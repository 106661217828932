<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            <i class="icon-list"></i>
            Novo Orçamento
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col md="2">
                <b-form-group label="Código" label-for="id">
                  <b-form-input id="id" type="text" value="NOVO" placeholder="Código" readonly />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Data de Emissão" label-for="data_emissao">
                  <b-form-input
                    id="data_emissao"
                    type="text"
                    :value="data_emissao | formatDate"
                    placeholder="Data de Emissão"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Data de Validade*" label-for="data_validade">
                  <!-- <my-date-time
                    :format="'dd/MM/yyyy'"
                    :type="'date'"
                    v-model="data_validade"
                    :title="'Data de Validade:'"
                  ></my-date-time> -->
                  <b-form-input
                    id="data_validade"
                    type="text"
                    :value="data_validade | formatDate"
                    readonly
                    placeholder="Data de Validade"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Previsão de Fechamento" label-for="previsao_fechamento" >
                  <b-form-input
                    id="previsao_fechamento"
                    type="date"
                    v-model="previsao_fechamento"
                    placeholder="Previsão de Fechamento"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Contato*" label-for="contato">
                  <b-form-input id="contato" type="text" v-model="contato" placeholder="Contato" />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Filial" label-for="filial">
                  <b-form-select v-model="filial" :options="options"></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group label="CNPJ*" label-for="cnpj">
                  <b-input-group>
                    <b-form-input
                      id="cnpj"
                      type="text"
                      v-model="cnpjCliente"
                      @keyup="buscarCliente()"
                      placeholder="CNPJ"
                      maxlength="18"
                    />
                    <b-input-group-append v-if="this.$route.params.cliente_codigo==null">
                      <b-button v-on:click="ChooseClient" variant="primary">
                        <i class="fa fa-search"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="7">
                <b-form-group label="Razão Social" label-for="razao_social">
                  <b-form-input
                    id="razao_social"
                    type="text"
                    :value="clientBasicInfo.razao_social"
                    placeholder="Razão Social"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  v-if="clientStatus != null"
                  label="Status do Cliente"
                  label-for="status"
                >
                  <h3>
                    <cliente-status id="status" :status="clientStatus" />
                  </h3>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <b-button v-on:click="cancel" variant="primary" class="w-100 mb-2">
                  <i class="fa fa-cancel"></i> Descartar
                </b-button>
              </b-col>
              <b-col md="8"></b-col>
              <b-col md="2">
                <b-button v-on:click="confirmCreate" class="w-100 mb-2" variant="success">
                  <i class="fa fa-save"></i> Salvar
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <cliente-escolha
      id="clienteEscolhaId"
      title="Escolha um cliente para criar o orçamento"
      v-on:onClienteSelecionado="onClienteSelecionado"
    ></cliente-escolha>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Orcamento from "../../../services/orcamento";
import Cliente from "../../../services/cliente";
import ClienteStatus from "../clientes/ClienteStatus";
import MyDateTime from "../../../components/ui/MyDateTime";
import ClienteEscolha from "../clientes/ClienteEscolha";

export default {
  name: "OrcamentoNovo",
  components: { ClienteStatus, MyDateTime, ClienteEscolha },
  data: () => ({
    data_emissao: new Date(),
    data_validade: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+10),
    previsao_fechamento: '',
    contato: null,
    orcamento_id: null,
    clientBasicInfo: new Object(),
    clienteEcontrado: false,
    cnpjCliente: '',
    filial: 1,
    options: [
      {value: 1, text: 'Maringá'},
      {value: 2, text: 'Itajaí'}
    ]
  }),
  mounted(){
    this.buscaClientes();
  },
  computed: {
    ...mapState("carrinho", {
      cliente_codigo: "cliente_codigo",
    }),
    clientStatus() {
      return this.clientBasicInfo.status;
    }
  },
  beforeMount() {
    if (this.$route.params.cliente_codigo)
      this.loadClientBasicInfo(this.$route.params.cliente_codigo);
  },
  methods: {
    ChooseClient() {
      this.clientBasicInfo = new Object();
      this.$bvModal.show("clienteEscolhaId");
    },
    onClienteSelecionado(cliente) {
      this.clientBasicInfo = cliente;
      this.contato = cliente.contato;
      this.clienteEcontrado = true;
      this.clientBasicInfo.cnpj = this.cnpjCliente;
      this.formatarCnpj();
    },
    loadClientBasicInfo(cliente_codigo) {
      if (cliente_codigo == null || cliente_codigo == 0) return;
      this.clientBasicInfo = new Object();
      Cliente.getBasicInfo(cliente_codigo, true)
        .then(response => {
          this.clientBasicInfo = response.data.data;
          this.contato = this.clientBasicInfo.contato;
        })
        .catch(e => {
          this.$helper.showErrorResponse(e);
        });
    },
    confirmCreate() {
      if (this.clientBasicInfo.codigo == null) {
        this.$helper.showMsg("Por favor escolha um cliente.", "warning");
        return;
      }

      this.data_validade = new Date(this.data_validade);
      if (this.data_validade == null || this.data_validade.toString().trim() == "") {
        this.$helper.showMsg(
          "Por favor informe a Data de Validade.",
          "warning"
        );
        return;
      }
      let data_comparacao = new Date();
      data_comparacao.setDate(data_comparacao.getDate() - 1);
      if(this.previsao_fechamento == null || new Date(this.previsao_fechamento) < data_comparacao)
      {
        this.$helper.showMsg(
          'Previsão de fechamento deve ser preenchida.',
          "warning"
        );
        return;
      }

      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja criar um novo Orçamento?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "Sim",
          cancelVariant: "danger",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.createOrcamento();
          }
        })
        .catch(err => {
          this.$helper.showErrorResponse(err);
        });
    },
    createOrcamento() {
      let data = {
        revenda_codigo: this.$store.state.login.resale.revenda_codigo,
        cliente_codigo: this.clientBasicInfo.codigo,
        data_validade: new Intl.DateTimeFormat('pt-BR').format(this.data_validade).split('/').reverse().join('-'),
        contato: this.contato,
        previsao_fechamento: this.previsao_fechamento,
        cd_filial: this.filial,
        cd_empresa: 1,
        status: 'D'
      };
      Orcamento.createOrcamento(data)
        .then(response => {
          if(this.filial == 1)
          {
            this.$store.commit("carrinho/CURRENT_CLIENT", data.cliente_codigo);
          }else
          {
            this.$store.commit("carrinhoitj/CURRENT_CLIENT_ITJ", data.cliente_codigo);
          }
          this.$router.push(
            "/vendas/orcamentos/orcamento/" + response.data.data.id + "/editar"
          );
        })
        .catch(e => {
          this.$helper.showErrorResponse(e);
        });
    },
    buscarCliente()
    {
      this.formatarCnpj();
      
      if(this.cnpjCliente.length == 18)
      {
        this.getClienteByCnpj();
      }
    },
    formatarCnpj()
    {
      let indice = this.cnpjCliente.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
      this.cnpjCliente = !indice[2] ? indice[1] : indice[1] + '.' + indice[2] + '.' + indice[3] + '/' + indice[4] + (indice[5] ? '-' + indice[5] : '');
    },
    cancel() {
      this.$router.push("/vendas/orcamentos");
    },
    buscaClientes()
    {
      Cliente.getAllClientes()
      .then(response => {
        this.listaClientes = response.data.data;
      })
      .catch(e => {
        this.$helper.showErrorResponse(e);
      });
    },
    getClienteByCnpj()
    {
      let cnpjComparar = this.cnpjCliente.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');
      this.clienteEcontrado = false;
      this.listaClientes.forEach(cliente => {
        cliente.cnpj = cliente.cnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');
        if(cliente.cnpj == cnpjComparar && !this.clienteEcontrado)
        {
          this.onClienteSelecionado(cliente);
        }
      });

      if(!this.clienteEcontrado)
      {
        this.clientBasicInfo.razao_social = null;
        this.clientBasicInfo.codigo = null;
        this.$helper.showMsg("Cliente não encontrado.", "warning");
      }
    }
  }
};
</script>